<template>
  <picture>
    <source :srcset="convertTo1080(photo)" type="image/webp" />
    <source :srcset="convertTo1080(getFallbackImage(photo))" type="image/jpeg" />
    <img
      :src="convertTo1080(getFallbackImage(photo))"
      class="carousel--promo-image img-responsive"
      :alt="`${photoAlt} - Rentfix`"
    />
  </picture>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  props: {
    photo: {
      default: '',
      require: true,
    },
    photoAlt: {
      default: '',
      require: true,
    },
  },
};
</script>
